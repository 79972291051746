<template>
  <div>
    <MaterialPreview
      :style="{ visibility: pageInited ? 'visible' : 'hidden' }"
      :materialTitle="materialTitle"
      :materialIntro="materialIntro"
      :skillArr="skillArr"
      :contentArr="contentArr"
    />
  </div>
</template>

<script setup>
  import { ref } from 'vue';

  import MaterialPreview from '@/components/MaterialPreview/MaterialPreview.vue';

  const pageInited = ref(false);
  const materialTitle = ref('');
  const materialIntro = ref('');
  const skillArr = ref([]);
  const contentArr = ref([]);

  function initData() {
    const dataJSON = window.localStorage.getItem('a-task-material-preview');
    if (!dataJSON) {
      return;
    }
    const dataObj = JSON.parse(dataJSON);
    const { title, introduce, all_skill, taskArr } = dataObj;
    materialTitle.value = title || '';
    materialIntro.value = introduce || '';
    skillArr.value = all_skill;
    contentArr.value = taskArr;

    pageInited.value = true;
  }
  initData();
</script>